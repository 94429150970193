import * as React from "react"
import { useDataStore }  from '../store/data';

import AudioButton from "../components/AudioButton"
import LanguageContext from "../context/LanguageContext";

import "../styles/TopUi.scss"


export default class NationsUi extends React.Component {

  static contextType = LanguageContext

  constructor(props) {
    super(props)

    console.log('Topui props', this.props);

    
   
  }

  render() {

    let year = new Date().getFullYear();
    let legal = useDataStore.getState().legal;
    let footer = legal.copyright.replace('[year]', year);

    return (
      <section className={ this.props.isMenu ? 'TopUi TopUiMenu' : 'TopUi' } ref={n => (this.mainEl = n)}>

        <div className="wrapper">
          { !this.props.nologo && <img src={`/images/common/logo${(this.context.language == "en")?``:`_${this.context.language}`}.png`} alt="" className="logo" /> }
          { !this.props.nologo && <div className="footer">{ footer }<br /><a href={ legal.privacy_terms_file } target="_blank">{ legal.privacy_terms }</a> | <div class="cookiePreferences ot-sdk-show-settings" target="_blank">{ legal.cookie_preferences }</div></div> }

          <div className="corner corner1"></div>
          <div className="corner corner2"></div>
          <div className="corner corner3"></div>
          <div className="corner corner4"></div>
        </div>

        {/* { !isMobile && <AudioButton /> } */}
        
      </section>
    )
  }
  
}
