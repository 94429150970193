import * as React from "react"
import gsap from "gsap"
import { isMobile } from 'react-device-detect';
import TransitionLink from 'gatsby-plugin-transition-link'

import CloseButton from '../CloseButton';

import { useShareableStore }  from '../../store/data';
import { useGlobalStore }  from '../../store/global';
import { useDataStore }  from '../../store/data';

import "../../styles/export.scss"

export default class ShareablePreview extends React.Component {

  constructor(props) {
    super(props)

    console.log('props', props);
    
    this.state = { 
      showPreview: false
    }

    this.mainEl = React.createRef()
  }

  componentDidMount() {

    // this.unsub = useShareableStore.subscribe((data) => {
    //     if(data && data.showPreview && !this.state.showPreview) this.animateIn(data.showPreview);
    // }, (state) => state.showPreview);

    this.setState({ currId: 0, arr: [this.videoItemEl, this.imgItemEl] });

    useGlobalStore.setState({ previewAnimateIn: this.animateIn.bind(this) });
  }

  animateIn(id) {
    gsap.fromTo(this.mainEl, .7, { display: 'block', autoAlpha: 0 }, { autoAlpha: 1, ease: 'Expo.easeOut' });
    this.setState({ showPreview: true });

    console.log('animate in', id);

    gsap.set(this.videoItemEl, { autoAlpha: 0 });
    gsap.set(this.imgItemEl, { autoAlpha: 0 });

    setTimeout(() => {
      if(id == 'video') {
          this.toVideo();
      }
      else {
          this.toImage();
      }  
    }, 200);

    

    console.log('share preview in');
  }

  animateOut() {
    gsap.to(this.mainEl, .7, { display: 'none', autoAlpha: 0, ease: 'Expo.easeOut' });
    this.setState({ showPreview: false });
  }

  next() {

    if(this.currId === 1) {
        this.toVideo();
    }
    else {
        this.toImage();
    }
  }

  toVideo() {
    let q = gsap.utils.selector(this.mainEl);


    gsap.set([this.videoItemEl, this.imgItemEl], { transform: 'none' });
    gsap.fromTo(this.videoItemEl, .5, { x: '-100%', y: '-50%' }, { autoAlpha: 1, x: '-50%', ease: 'Expo.easeOut', delay: .1 });
    this.videoEl.play();
    gsap.fromTo(this.imgItemEl, .5, { y: '-50%', x: '-50%' }, { autoAlpha: 0 , y: '-50%', x: '0%', ease: 'Expo.easeOut' });

    gsap.to(q('.dot1'), .5, { opacity: 1 });
    gsap.to(q('.dot2'), .5, { opacity: .5 });

    this.currId = 0;
  }

  toImage() {
    let q = gsap.utils.selector(this.mainEl);

    gsap.set([this.videoItemEl, this.imgItemEl], { transform: 'none' });
    gsap.fromTo(this.videoItemEl, .5, { y: '-50%', x: '-50%' }, { autoAlpha: 0, y: '-50%', x: '-100%', ease: 'Expo.easeOut', });
    gsap.fromTo(this.imgItemEl, .5, { x: '0%', y: '-50%' }, { autoAlpha: 1, x: '-50%', ease: 'Expo.easeOut', delay: .1 });

    gsap.to(q('.dot1'), .5, { opacity: .5 });
    gsap.to(q('.dot2'), .5, { opacity: 1 });

    this.currId = 1;
  }

  onResize() {
    
  }

  onCloseClick() {
    this.animateOut();
  }

  onOver() {

  }

  onOut() {

  }

  onClickShare(type) {
    console.log('PREVIEW SHARE CLIKCK', type);
    useGlobalStore.getState().exportClickShare && useGlobalStore.getState().exportClickShare(type);
  }

  render() {

    const share = useDataStore.getState().export.share;
    const download = useDataStore.getState().export.download;
    const label = isMobile ? share : download;

    let { language } = useDataStore.getState();

    return (
      <section className="shareablePreview" ref={n => (this.mainEl = n)}>
            
            <div className="closeButton" ref={n => (this.mainEl = n)} onClick={ this.onCloseClick.bind(this) } onMouseEnter={ this.onOver.bind(this) } onMouseLeave={ this.onOut.bind(this) }>
                
                <div className="closeline closeline1"></div>
                <div className="closeline closeline2"></div>
            </div>

          <div className="wrapper">
            <div className={ "items items-" + language } >
                <div className="item" ref={n => (this.videoItemEl = n)}>
                    <video src={useShareableStore.getState().previewVideo} muted playsInline autoPlay ref={n => (this.videoEl = n)} onClick={this.next.bind(this) } ></video>
                    <a className="btnDownload" onClick={this.onClickShare.bind(this,'video')}>{ label }</a>
                </div>
                <div className="item" ref={n => (this.imgItemEl = n)}>
                    <img src={useShareableStore.getState().previewImage} alt="" className="src" onClick={this.next.bind(this) } />
                    <a className="btnDownload" onClick={this.onClickShare.bind(this,'image')}>{ label }</a>
                </div>
            </div>
            
          </div>
          <div className="shareablePagination">
            <div className="dot dot1" onClick={ this.toVideo.bind(this) }></div>
            <div className="dot dot2" onClick={ this.toImage.bind(this) }></div>
        </div>
      </section>
    )
  }
  
}