import React from 'react'
import "../styles/menu_button.scss"
import gsap from "gsap"
import { useMenuStore }  from '../store/menu';
import { useAudioStore }  from '../store/audio';

import MenuContext from '../context/MenuContext'

export default class MenuButton extends React.Component {

    static contextType = MenuContext

    constructor(props) {
        super(props)

        this.state = { mode: 'burger' };
    
        console.log('MenuButton', this.props);   
        this.mainEl = React.createRef();

        
    }

    componentDidMount() {
        if(this.props.isOpen) {
            this.toX();
        }
    }

    toBurger() {
        let q = gsap.utils.selector(this.mainEl);

        gsap.to(this.mainEl, .5, { overwrite: true, autoAlpha: 1, scale: 1, ease: 'Expo.easeOut', delay: 0 });

        gsap.to(q('.menuline1'), 2, { overwrite: true, autoAlpha: 1, x: 0, y: 0, rotate: '0deg', ease: 'Expo.easeOut', delay: 0 });
        gsap.to(q('.menuline2'), 2, { overwrite: true, autoAlpha: 1, x: 0, y: 0, scaleX: 1, ease: 'Expo.easeOut', delay: 0 });
        gsap.to(q('.menuline3'), 2, { overwrite: true, autoAlpha: 1, x: 0, y: 0, rotate: '0deg', ease: 'Expo.easeOut', delay: 0 });

        this.setState({ mode: 'burger' });
        // this.mainEl.mode = 'burger';
    }

    toX() {
        let q = gsap.utils.selector(this.mainEl);

        gsap.to(q('.menuline1'), .5, { overwrite: true, autoAlpha: 1, transformOrigin: '0% 0%', x: 6, y: -1, rotate: '45deg', ease: 'Expo.easeOut', delay: 0 });
        gsap.to(q('.menuline2'), .5, { overwrite: true, scaleX: 0, ease: 'Expo.easeOut', delay: 0, autoAlpha: 0 });
        gsap.to(q('.menuline3'), .5, { overwrite: true, autoAlpha: 1, transformOrigin: '0% 0%', x: 5, y: 2, rotate: '-45deg', ease: 'Expo.easeOut', delay: 0 });

        this.setState({ mode: 'x' });
        // this.mainEl.mode = 'x';
    }

    onOver() {

        if(this.mainEl.isHidden) return;

        let q = gsap.utils.selector(this.mainEl);

        gsap.to(q('.menuline'), .5, { background: '#d29341', ease: 'Expo.easeOut', delay: 0 });

        if(this.state.mode === 'burger') {
            gsap.to(q('.menuline1'), .5, { y: 2, ease: 'Expo.easeOut', delay: 0 });
            gsap.to(q('.menuline3'), .5, { y: -2, ease: 'Expo.easeOut', delay: 0 });
            gsap.to(q('.menuline2'), .5, { transformOrigin: '50% 50%', scaleX: .5, ease: 'Expo.easeOut', delay: 0 });    
        }
        else if(this.state.mode === 'x') {
            gsap.to(this.mainEl, .5, { autoAlpha: 1, scale: .9, ease: 'Expo.easeOut', delay: 0 });
        }

        useAudioStore.getState().play('roll');
        
    }

    onOut() {

        if(this.mainEl.isHidden) return;

        let q = gsap.utils.selector(this.mainEl);

        gsap.to(q('.line'), .5, { background: '#f1d1bc', ease: 'Expo.easeOut', delay: 0 });
        
        if(this.state.mode === 'burger') {
            gsap.to(q('.menuline1'), .5, { overwrite: true, autoAlpha: 1, y: 0, ease: 'Expo.easeOut', delay: 0 });
            gsap.to(q('.menuline3'), .5, { overwrite: true, autoAlpha: 1, y: 0, ease: 'Expo.easeOut', delay: 0 });
            gsap.to(q('.menuline2'), .5, { overwrite: true, autoAlpha: 1, scaleX: 1, ease: 'Expo.easeOut', delay: 0 });
            // gsap.to(q('.line'), .5, { color: 'green', ease: 'Linear.easeNone' });
        }
        else if(this.state.mode === 'x') {
            gsap.to(this.mainEl, .5, { autoAlpha: 1, scale: 1, ease: 'Expo.easeOut', delay: 0 });
        }
    }

    onClick() {
        console.log('onMenuButtonClick');

        useAudioStore.getState().play('click');

        let q = gsap.utils.selector(this.mainEl);

        this.mainEl.isHidden = true;

        gsap.set(q(this.mainEl), { pointerEvents: 'none' });

        if(this.state.mode === 'burger') {
            gsap.to(q('.line'), .7, { overwrite: true, scaleX: 0, ease: 'Expo.easeOut', stagger: 0.05 });
        }
        else {
            gsap.to(q(this.mainEl), .7, { overwrite: true, scale: 0, ease: 'Expo.easeOut' });
        }

        setTimeout(() => {
            // this.context.toggleMenu();    

            useMenuStore.setState({ isOpen: !useMenuStore.getState().isOpen });

            setTimeout(() => {
                if(this.state.mode === 'burger') {
                    gsap.set(q('.line'), { overwrite: true, scaleX: 1 });
                    gsap.set(q('.menuline1'), { overwrite: true, autoAlpha: 1, y: 0, ease: 'Expo.easeOut', delay: 0 });
                    gsap.set(q('.menuline3'), { overwrite: true, autoAlpha: 1, y: 0, ease: 'Expo.easeOut', delay: 0 });
                    gsap.set(q('.menuline2'), { overwrite: true, autoAlpha: 1, scaleX: 1, ease: 'Expo.easeOut', delay: 0 });
                }
                else {
                    gsap.set(q(this.mainEl), { overwrite: true, scale: 1, delay: 1 });
                }

                gsap.set(q(this.mainEl), { pointerEvents: 'all' });

                this.mainEl.isHidden = false;
            }, 1500);
        }, 500);

        
        // this.toX();
    }

    render() {

        return(
            <div className="menuButton" ref={n => (this.mainEl = n)} onClick={ this.onClick.bind(this) } onMouseEnter={ this.onOver.bind(this) } onMouseLeave={ this.onOut.bind(this) }>
                <div className="menuline menuline1"></div>
                <div className="menuline menuline2"></div>
                <div className="menuline menuline3"></div>
            </div>
        )
    }
}