import React, { Component } from 'react'
import { isMobile, isTablet } from 'react-device-detect';
import Menu from "./Menu"
import '../styles/global.scss'
import gsap from "gsap"
import RotateDevice from "../components/RotateDevice"
import AudioButton from "../components/AudioButton"
import { LoadData } from "../components/LoadData"
import AudioHandling from "../components/Audio"
import LanguageContext from '../context/LanguageContext'
import { useAudioStore } from "../store/audio";
import ShareablePreview from '../components/export/ShareablePreview'
import * as Tone from 'tone'

export default class Layout extends Component {

    static contextType = LanguageContext

    constructor(props) {
        super(props);
        this.state = { language: 'en' };
    
        this.changeLanguage = (id) => {
            console.log('changing language context', id);
            this.setState({ language: id })
        }

        this.state = {
            language: 'en', changeLanguage: this.changeLanguage
        };

        this.mainEl = React.createRef()
    }

    componentDidMount() {
    
        gsap.to(this.mainEl, .7, { autoAlpha: 1, delay: .5 });

        if(isMobile) {
            let res = this.onResize.bind(this)
            window.addEventListener('resize', res);
            this.onResize();
        }      
    
    }

    onFocus() {
        console.log('onfocus');
    }

    onBlur() {
        console.log('onblur');
    }

    onResize() {
        if(!this.mainEl) return;

        let mainEl = this.mainEl.getBoundingClientRect();

        this.setState({ isMobileWide: mainEl.width > mainEl.height })
      }

    onClick() {
        if(!this.mainEl || !this.mainEl.isClicked) {
            console.log('first click');
            // let make sure we're allowed to play sound
            // Tone.start();

            this.mainEl.isClicked = true;
            
            useAudioStore.setState({ active: true });
            
            if(useAudioStore.getState().onHome) {
                useAudioStore.getState().play('wind_loop');    
                
            }
            gsap.fromTo(useAudioStore.getState().button, .5, { display: 'block', opacity: 0 }, { opacity: 1 });
            // let q = gsap.utils.selector(this.mainEl);
            // gsap.to(q('.audioButton'), .5, { display: 'block' });
            
        }
    }

    render(){
        const { children } = this.props

        return(
            <section className="layout" ref={n => (this.mainEl = n)} onClick={ this.onClick.bind(this) } >                  
                <LoadData language={this.state.language} />
                { !isMobile && <AudioHandling /> }
                <LanguageContext.Provider value={this.state} >
                    <div className="content">{children}</div>        
                    <Menu />
                    <ShareablePreview />
                    <RotateDevice />

                    { !isMobile && <AudioButton /> }
                </LanguageContext.Provider>
            </section>
        )
    }
}


