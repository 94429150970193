import React from 'react'
import gsap from "gsap"
import { useAudioStore }  from '../store/audio';

export default class MenuButton extends React.Component {

    constructor(props) {
        super(props)

        this.state = {};
    
        this.mainEl = React.createRef();
        
    }

    componentDidMount() {
        this.unsub = useAudioStore.subscribe((active) => {
            this.setState({ audioActive: active });
        }, (state) => state.active);

        useAudioStore.setState({ button: this.mainEl})

        console.log('did mount button');

    }

    componentDidUpdate() {
        
    }

    onOver() {
        let q = gsap.utils.selector(this.mainEl);
        // gsap.to(q('.menuline'), .5, { background: '#d29341', ease: 'Expo.easeOut', delay: 0 });        
    }

    onOut() {
        let q = gsap.utils.selector(this.mainEl);

        // gsap.to(q('.line'), .5, { background: '#f1d1bc', ease: 'Expo.easeOut', delay: 0 });
        
    }

    onClick() {

        useAudioStore.getState().toggle();
    
        let q = gsap.utils.selector(this.mainEl);  
    }

    render() {

        let hideAudioButton = useAudioStore.getState().hideAudioButton;

        return(
            <div className="audioButton" ref={n => (this.mainEl = n)} onClick={ this.onClick.bind(this) } onMouseEnter={ this.onOver.bind(this) } onMouseLeave={ this.onOut.bind(this) }>
                { !hideAudioButton && <img src={"/images/common/audio-" + (useAudioStore.getState().active === true ? 'on':'off') + ".png"} /> }
            </div>
        )
    }
}