exports.components = {
  "component---src-pages-[id]-js": () => import("./../../../src/pages/[id].js" /* webpackChunkName: "component---src-pages-[id]-js" */),
  "component---src-pages-cabbagesmash-js": () => import("./../../../src/pages/cabbagesmash.js" /* webpackChunkName: "component---src-pages-cabbagesmash-js" */),
  "component---src-pages-fr-[id]-js": () => import("./../../../src/pages/fr/[id].js" /* webpackChunkName: "component---src-pages-fr-[id]-js" */),
  "component---src-pages-fr-index-js": () => import("./../../../src/pages/fr/index.js" /* webpackChunkName: "component---src-pages-fr-index-js" */),
  "component---src-pages-fr-nation-[id]-js": () => import("./../../../src/pages/fr/nation/[id].js" /* webpackChunkName: "component---src-pages-fr-nation-[id]-js" */),
  "component---src-pages-fr-quiz-index-js": () => import("./../../../src/pages/fr/quiz/index.js" /* webpackChunkName: "component---src-pages-fr-quiz-index-js" */),
  "component---src-pages-fr-result-[id]-js": () => import("./../../../src/pages/fr/result/[id].js" /* webpackChunkName: "component---src-pages-fr-result-[id]-js" */),
  "component---src-pages-fr-trailer-js": () => import("./../../../src/pages/fr/trailer.js" /* webpackChunkName: "component---src-pages-fr-trailer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nation-[id]-js": () => import("./../../../src/pages/nation/[id].js" /* webpackChunkName: "component---src-pages-nation-[id]-js" */),
  "component---src-pages-nation-index-js": () => import("./../../../src/pages/nation/index.js" /* webpackChunkName: "component---src-pages-nation-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-quiz-bg-[id]-js": () => import("./../../../src/pages/quizBg/[id].js" /* webpackChunkName: "component---src-pages-quiz-bg-[id]-js" */),
  "component---src-pages-quiz-js": () => import("./../../../src/pages/quiz.js" /* webpackChunkName: "component---src-pages-quiz-js" */),
  "component---src-pages-result-[id]-js": () => import("./../../../src/pages/result/[id].js" /* webpackChunkName: "component---src-pages-result-[id]-js" */),
  "component---src-pages-result-index-js": () => import("./../../../src/pages/result/index.js" /* webpackChunkName: "component---src-pages-result-index-js" */),
  "component---src-pages-symbol-air-js": () => import("./../../../src/pages/symbol/air.js" /* webpackChunkName: "component---src-pages-symbol-air-js" */),
  "component---src-pages-symbol-earth-js": () => import("./../../../src/pages/symbol/earth.js" /* webpackChunkName: "component---src-pages-symbol-earth-js" */),
  "component---src-pages-symbol-fire-js": () => import("./../../../src/pages/symbol/fire.js" /* webpackChunkName: "component---src-pages-symbol-fire-js" */),
  "component---src-pages-symbol-water-js": () => import("./../../../src/pages/symbol/water.js" /* webpackChunkName: "component---src-pages-symbol-water-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-tests-fetchtester-js": () => import("./../../../src/pages/tests/fetchtester.js" /* webpackChunkName: "component---src-pages-tests-fetchtester-js" */),
  "component---src-pages-tests-score-balancer-js": () => import("./../../../src/pages/tests/scoreBalancer.js" /* webpackChunkName: "component---src-pages-tests-score-balancer-js" */),
  "component---src-pages-trailer-js": () => import("./../../../src/pages/trailer.js" /* webpackChunkName: "component---src-pages-trailer-js" */),
  "component---src-pages-uploader-canvas-js": () => import("./../../../src/pages/uploader/canvas.js" /* webpackChunkName: "component---src-pages-uploader-canvas-js" */)
}

