import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import gsap from "gsap"
import { isTouch } from 'react-device-detect';


import MenuButton from "./MenuButton"
import TopUi from "../components/TopUi"
import { useMenuStore }  from '../store/menu';
import { useDataStore }  from '../store/data';
import { useAudioStore }  from '../store/audio';

import "../styles/menu.scss"

const linkStyles = {
    marginRight: 10
};

export default class Menu extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            isOpen: false
        }

        console.log('MenuButton', this.props);   

        this.mainEl = React.createRef()
    }

    componentDidMount() {
        this.unsub = useMenuStore.subscribe((isOpen) => {
            this.setState({isOpen})
        }, (state) => state.isOpen);

        this.setState({ isOpen: useMenuStore.getState().isOpen })
    }
    
    componentDidUpdate() {

        if(!useMenuStore.getState().isOpen) {
            this.animateOut();
        }
        else if(useMenuStore.getState().isOpen) {
            this.animateIn();
        }    
      }

      animateIn() {
        let q = gsap.utils.selector(this.mainEl);

        gsap.fromTo(this.mainEl, .7, { overwrite: true, autoAlpha: 0, display: 'block' }, { autoAlpha: 1, ease: 'Expo.easeOut '});
        gsap.set(this.contentEl, { x: '-50%', y: '-50%' }); // css bug workaround
        gsap.fromTo(q('.button'), 1.4, { overwrite: true, y: 100, autoAlpha: 0 }, { y: 0, autoAlpha: 1, ease: 'Expo.easeOut', stagger: .1, delay: .5 });
        gsap.set(q('.legalButton'), { transform: 'none' });
        gsap.fromTo(q('.legalButton'), 1.4, { overwrite: true, y: 100, x: '-50%', autoAlpha: 0 }, { y: 0, autoAlpha: 1, ease: 'Expo.easeOut', stagger: .1, delay: .5 });

        gsap.fromTo(q('.line'), 1.4, { overwrite: true, scaleX: 0, background: '#FFF' }, { scaleX: 1, background: 'rgba(255,255,255,.1)', ease: 'Expo.easeOut', delay: 1 });
        
        this.mainEl.res = this.onResize.bind(this)
        window.addEventListener('resize', this.mainEl.res);
        // this.setState({ onResize: res });

        this.onResize();
      }

      animateOut() {
        let q = gsap.utils.selector(this.mainEl);

        gsap.to(this.mainEl, 1, { overwrite: true, autoAlpha: 0, display: 'none', delay: 1 });
        gsap.to(q('.button, .legalButton'), 1, { overwrite: true, y: -100, autoAlpha: 0, ease: 'Expo.easeIn', stagger: .1, delay: 0 });
        gsap.to(q('.button, .legalButton'), 1, { autoAlpha: 0, ease: 'Linear.easeNone', stagger: .1, delay: 0 });

        gsap.to(q('.line'), .7, { overwrite: true, scaleX: 0, ease: 'Expo.easeOut' });
        
        window.removeEventListener('resize', this.mainEl.res);
      }

      onResize() {

        if(!this.mainEl || !this.contentEl) return;

        let mainEl = this.mainEl.getBoundingClientRect();
        let contentEl = this.contentEl.getBoundingClientRect();

        

        if(contentEl.height > mainEl.height - 100) {
            gsap.set(this.contentEl, { x: '-50%', left: '50%', top: 100, y: 0, paddingBottom: 100 });
        }
        else {
            gsap.set(this.contentEl, { overwrite: true, x: '-50%', left: '50%', top: '50%', y: '-50%', paddingBottom: 0 });
        }

        
      }

      onClick() {
        useMenuStore.setState({ isOpen: false });
        useAudioStore.getState().play('click2');
      }

      onOver() {
        useAudioStore.getState().play('roll');
      }

      onOut() {

      }

      onLegalButtonOver(e) {

        if(isTouch) return;

        useAudioStore.getState().play('roll');
      }

      onLegalButtonOut(e) {
        if(isTouch) return;
      }
      
      onClickSocial(path) {
        window.open(path, '_blank')
      }

      render() {

        let data = useDataStore.getState().menu;
        let legal = useDataStore.getState().legal;
        console.log('menu legal', legal);
        if(!data) return;

        return(
            <div className="menu" ref={n => (this.mainEl = n)}>
                <img src="/images/menu/bg.jpg" alt="" className="bgImg" />
                <div className="scroller">
                    <MenuButton isOpen={true} />

                    <div className="content" ref={n => (this.contentEl = n)}>

                    {
                        data.items.map(
                          (item, i) => (

                                (item.path.indexOf('http') === -1) && (

                                    <div className="button" onClick={ this.onClick.bind(this) } onMouseEnter={ this.onOver.bind(this) } onMouseLeave={ this.onOut.bind(this) }>
                                        <TransitionLink style={linkStyles} to={ item.path } entry = {{ length: 1, delay: 1 }} exit={{ length: 1 }} >
                                            { item.label } 
                                        </TransitionLink>
                                    </div>
                                )
                            )
                        )
                    }

                    {

                        data.items.map(
                            (item, i) => (
  
                                  (item.path.indexOf('http') !== -1) && (
  
                                      <div className="button" onClick={ this.onClickSocial.bind(this, item.path) } onMouseEnter={ this.onOver.bind(this) } onMouseLeave={ this.onOut.bind(this) }>
                                          <div>{ item.label }</div>
                                      </div>
                                  )
  
                              )
                          )
                    }
                    

                        <div className="legal">
                            <div className="line"></div>

                            <div className="legalButton btnPrivacy">
                                <a href={legal.privacy_terms_file} target="_blank">
                                    { legal.privacy_terms }
                                </a>
                            </div>
                            
                            <div className="legalButton">
                                <div className="btnCookie ot-sdk-show-settings">
                                    { legal.cookie_preferences }
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>

                <TopUi isMenu={true} nologo={true} />
            </div>
        )
      }
    
}
