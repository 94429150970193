import React from 'react'
import { useAudioStore } from '../store/audio';
import { Sampler, Player, Volume } from "tone";
import { isSafari, isIOS } from 'react-device-detect';


export default class AudioHandling extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loaded: false
        }


    }

    componentDidMount() {
        this.init();
    }

    init() {

        const type = (isSafari || isIOS) ? 'mp3' : 'ogg';
        console.log('audio file type', type);

        let audioData = [
            { id: 'roll', src: '/audio/ui/sx_ui_mouseover.' + type },
            { id: 'click', src: '/audio/ui/sx_ui_click_confirm_generic_r2.' + type },
            { id: 'click2', src: '/audio/ui/sx_ui_bell_click_r1.' + type },
            { id: 'wind_loop', src: '/audio/home/sx_main_page_wind_loop_v2_r1.' + type, loop: true },
            { id: 'yipyip', src: '/audio/home/sx_appa_boost_r2.' + type },
            { id: 'throw_boomerang', src: '/audio/quiz/sx_boomerang_throw_r1.' + type },

            { id: 'caught_a_fish', src: '/audio/quiz/sx_caught_a_fish_r1.' + type },
            { id: 'flopping_fish', src: '/audio/quiz/sx_flopping_fish_r1.' + type },
            
            { id: 'stomach_grumbles', src: '/audio/quiz/sx_stomach_growl_01_r1.' + type },

            { id: 'cart', src: '/audio/quiz/sx_cabbage_cart_arrive_r1.' + type },
            
            { id: 'throw_rock', src: '/audio/quiz/sx_cabbage_throw_catch_r1.' + type },

            { id: 'crate_slip_fall', src: '/audio/quiz/sx_crate_slip_fall_r1.' + type },

            { id: 'wake_on_boat', src: '/audio/quiz/sx_amb_wake_up_in_a_boat_r1.' + type, loop: true },
            { id: 'kangaroo_docks', src: '/audio/quiz/sx_amb_kangaroo_docks_arrive_r1.' + type, loop: true },
            { id: 'kangaroo_docks_busy', src: '/audio/quiz/sx_amb_kangaroo_docks_busy_path_r1.' + type, loop: true },

            { id: 'beautiful_water_front', src: '/audio/quiz/sx_amb_beautiful_water_front_r1.' + type, loop: true },

            { id: 'air_steam_loop', src: '/audio/quiz/air/sx_air_steam_loop_r1.' + type, loop: true },
            { id: 'earth_tonal_loop', src: '/audio/quiz/earth/sx_earth_tonal_loop_r1.' + type, loop: true },

            { id: 'flames_touch_loop', src: '/audio/quiz/fire/sx_flames_touch_loop_r1.' + type, loop: true },

            { id: 'ripple_loop', src: '/audio/quiz/water/sx_ripple_loop_r1.' + type, loop: true },    
            
            { id: 'up_the_hill', src: '/audio/quiz/sx_amb_up_the_hill_r1.' + type, loop: true },

            { id: 'outside_seer', src: '/audio/quiz/sx_amb_outside_the_seer_r1.' + type, loop: true },
            { id: 'inside_seer', src: '/audio/quiz/sx_amb_seer_inside_fire_r1.' + type, loop: true },

            { id: 'reveal_hit', src: '/audio/result/mx_tribe_reveal_1_r1.' + type },
        ]

        let data = {
            active: false, play: this.play.bind(this), toggle: this.toggle.bind(this), volume: new Volume().toDestination(),
            mute: this.mute.bind(this), unmute: this.unmute.bind(this), stop: this.stop.bind(this),
            audio: {},
        };

        for(let i in audioData) {
            let d = audioData[i];
            if(d.loop) {
                data.audio[d.id] = new Player({ url: d.src, loop: true, fadeOut: 1, name: d.id }).toDestination();
            }
            else {
                data.audio[d.id] = new Player({ url: d.src, name: d.id }).toDestination();
            }

        }

        useAudioStore.setState(data);

        window.addEventListener("focus", this.unmute);
        window.addEventListener("blur", this.mute);
    }

    // firstClick(){
    //     console.log('start tone js');
    //     Tone.start();
    // }

    mute() {

        // used when muting audio without user action
        // offsite / 

        console.log('mute');

        try {
            let audio = useAudioStore.getState().audio;

            for (let i in audio) {
                audio[i].mute = true;
            }
        } catch (e) {
            console.log(e);
        }

    }

    unmute() {

        if (!useAudioStore.getState().active) return;
        try {
            let audio = useAudioStore.getState().audio;

            for (let i in audio) {
                audio[i].mute = false;
            }
        } catch (e) {
            console.log(e);
        }
    }

    toggle() {
        useAudioStore.setState({ active: !useAudioStore.getState().active });

        if (useAudioStore.getState().active) {
            this.unmute();
        }
        else {
            this.mute();
        }
    }

    play(id) {
        

        if (!useAudioStore.getState().active) return;

        if(useAudioStore.getState().audio[id]._loop) {

            // stop current loop if already playing
            if(this.state.lastLoopId) {
                this.stop(this.state.lastLoopId);
            }

            this.setState({ lastLoopId: id })
        }

        console.log('play audio', id, 'loop:' + useAudioStore.getState().audio[id]._loop);

        try {
            useAudioStore.getState().audio[id].start();
        } catch (e) {
            console.log(e);
        }

        
    }


    stop(id) {

        if (!useAudioStore.getState().active) return;
        try {
            useAudioStore.getState().audio[id].stop();

            if(useAudioStore.getState().audio[id]._loop) {
                this.setState({ lastLoopId: false })
            }
        } catch (e) {
            console.log(e);
        }

        
    }

    render() {

        return (
            <></>
        )
    }

}
