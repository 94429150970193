import * as React from "react"
import gsap from "gsap"
import { useDataStore }  from '../store/data';

import "../styles/rotate-device.scss"


export default class RotateDevice extends React.Component {

  constructor(props) {
    super(props)

    console.log('rotatte props', props);

    this.state = {
      show: false
    }

    this.mainEl = React.createRef()
   
  }

  componentDidMount() {

    console.log('roate device mount');

    this.onResize();
    window.addEventListener('resize', this.onResize.bind(this));
    
  }

  onResize() {
    let show =(window.innerWidth > window.innerHeight && window.innerHeight < 400);
    this.setState({ show })
  }

  render() {

    let data = useDataStore.getState().rotate_device;

    if(!this.state.show) return;

    return (
      <section className="RotateDevice">

        <div className="content">
            <img src="/images/common/rotate-screen.png" alt="" className="icon" />
            <div className="label">{ data.label }</div>
        </div>
          
      </section>
    )
  }
  
}