import { useStaticQuery, graphql } from "gatsby"
import { useDataStore }  from '../store/data';

export function LoadData(props) {
    
    const data = useStaticQuery(graphql`
        query {
            md: allMarkdownRemark( sort: { frontmatter: {id: ASC} } ) {
                nodes {
                    frontmatter {
                        id
                        title
                        language
                        landing_page {
                            description
                            id
                            title
                            subtitle
                            explore
                            start_button
                            yipyip
                            nation {
                                id
                                title
                            }
                            watch_trailer
                        }
                        menu {
                            items {
                                path
                                label
                            }
                        }
                        resultpage {
                            prominent_traits
                            results
                            start_over
                            scroll
                            nations {
                                id
                                color
                                you_are
                                title
                                description
                            }
                        }
                        addphoto {
                            title
                            subtitle
                            description
                            start
                            camera
                            upload
                            take_photo
                            continue
                            retake_photo
                            upload_image
                            upload_new
                            upload_error
                            accessing_camera
                            back
                            adjust_image
                            please_activate_camera
                            legal1
                            legal2
                            tap
                            click
                            to_upload
                        }
                        export {
                            generating_video
                            generating_shareables
                            download
                            share
                            preview
                            retake_photo
                            try_quiz_again
                            animals {
                                otter_penguin
                                badgermole
                                winged_lemur
                                sky_bison
                                ostrich_horse
                                shirshu
                                possum_chicken
                                messenger_hawk
                            }
                            achievements {
                                ocean_adventurer
                                toolsmith
                                ecofriendly_one
                                speedy_one
                                gentle_spirit
                                assertive_one
                                champion_of_justice
                                nimble_one
                                stress_relief_expert
                                cool_and_calm
                                playful_spirit
                                excellent_focus
                                champion_of_kindness
                                curious_one
                                home_body
                                sea_squid_gourmet
                                boomerang_master
                                ocean_navigator
                                technology_expert
                                warrior_heart
                                spicy_food_chef
                                builder_of_strongholds
                                agricultural_pro
                                hot_air_balloonist
                                bison_polo_champion
                                master_sculptor
                                tattoo_artist
                                glider_pilot
                            }
                        }
                        nations {
                            title
                            subtitle
                            explore_other_nations
                            items {
                                id
                                color
                                color2
                                the
                                title1
                                title2
                                tagline
                                traits {
                                    title
                                    items {
                                        label
                                    }
                                }
                                description {
                                    title
                                    copy
                                }
                                question
                            }
                        }
                        quiz {
                            choose_one
                            loading
                            items {
                                label
                                copy
                                id
                                options {
                                    label
                                }
                            }
                            traits {
                                and
                                also
                                items {
                                    id
                                    label
                                    header
                                    copy
                                    extra
                                }
                            }
                        }
                        rotate_device {
                            label
                        }
                        legal {
                            privacy_terms
                            privacy_terms_file
                            copyright
                            cookie_preferences
                        }
                        game {
                            buttons {
                                start
                                mute
                                unmute
                            }
                            press_start
                            choose_an_element
                            intro_quote
                            end_quote {
                                air {
                                    line1
                                    line2
                                }
                                water {
                                    line1
                                    line2
                                }
                                fire {
                                    line1
                                    line2
                                }
                                earth {
                                    line1
                                    line2
                                }
                            }
                            time_out
                        }
                        promo_overlay {
                            continue_to_site
                            avatar_state_filter {
                                image
                                buttons {
                                    instagram {
                                        label
                                        url
                                    }
                                    tiktok {
                                        label
                                        url
                                    }
                                }
                            }
                            now_streaming {
                                button {
                                    label
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    let languageData;
    for(let i in data.md.nodes) {
        let node = data.md.nodes[i];

        if(node.frontmatter.language == props.language) {
            languageData = node.frontmatter;
        }
    }
    console.log('load MD data', languageData, data.md.nodes);
    useDataStore.setState(languageData);
}