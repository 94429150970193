import React from 'react'
import "../styles/menu_button.scss"
import gsap from "gsap"
import { useAudioStore }  from '../store/audio';
import { useDataStore }  from '../store/data';
import TransitionLink from 'gatsby-plugin-transition-link'

export default class CloseButton extends React.Component {

    constructor(props) {
        super(props)

        this.state = { mode: 'x', path: '/', active: true };
    
        console.log('CloseButton', this.props);   
        
        this.mainEl = React.createRef();
        this.linkEl = React.createRef();

        
    }

    componentDidMount() {
        this.toX();

        if(this.props.path) {
            this.setState({ path: this.props.path })
        }
    }

    toX() {
        let q = gsap.utils.selector(this.mainEl);

        gsap.to(q('.menuline1'), .5, { overwrite: true, autoAlpha: 1, transformOrigin: '0% 0%', x: 6, y: -1, rotate: '45deg', ease: 'Expo.easeOut', delay: 0 });
        gsap.to(q('.menuline2'), .5, { overwrite: true, scaleX: 0, ease: 'Expo.easeOut', delay: 0, autoAlpha: 0 });
        gsap.to(q('.menuline3'), .5, { overwrite: true, autoAlpha: 1, transformOrigin: '0% 0%', x: 5, y: 2, rotate: '-45deg', ease: 'Expo.easeOut', delay: 0 });

        this.setState({ mode: 'x' });
        // this.mainEl.mode = 'x';
    }

    onOver() {
        let q = gsap.utils.selector(this.mainEl);

        gsap.to(q('.menuline'), .5, { background: '#d29341', ease: 'Expo.easeOut', delay: 0 });
        gsap.to(this.mainEl, .5, { autoAlpha: 1, scale: .9, ease: 'Expo.easeOut', delay: 0 });
        useAudioStore.getState().play('roll');
        
    }

    onOut() {
        let q = gsap.utils.selector(this.mainEl);

        gsap.to(q('.line'), .5, { background: '#f1d1bc', ease: 'Expo.easeOut', delay: 0 });
        gsap.to(this.mainEl, .5, { autoAlpha: 1, scale: 1, ease: 'Expo.easeOut', delay: 0 });
    }

    onClick() {
        if(!this.state.active) return;
        console.log('onCloseButtonClick');

        useAudioStore.getState().play('click');

        let q = gsap.utils.selector(this.mainEl);

        gsap.to(q(this.mainEl), .7, { overwrite: true, scale: 0, ease: 'Expo.easeOut' });

        setTimeout(() => {
            this.linkEl.click();
            this.setState({ active: false })
        }, 500);
    }

    render() {

        let { language } = useDataStore.getState();
        let link = (language == 'en' ? '' : '/fr') + this.state.path;

        return(
            
            <div className="closeButton">
                <TransitionLink ref={n => (this.linkEl = n)} to={ link } entry = {{ length: 1, delay: 0 }} exit={{ length: 1, delay: 0 }} ></TransitionLink>
                <div className="menuButton" ref={n => (this.mainEl = n)} onClick={ this.onClick.bind(this) } onMouseEnter={ this.onOver.bind(this) } onMouseLeave={ this.onOut.bind(this) }>
                
                    <div className="menuline menuline1"></div>
                    <div className="menuline menuline2"></div>
                    <div className="menuline menuline3"></div>
                </div>
            </div>
           
        )
    }
}